.verifycode{
    .logo-img{
        // width: 2.8vw !important;
        // height: 2.8vw !important;
        margin: 1.66vw 1.66vw 0;
      }
      .para16{
        padding: 0 2vw;
      }
}
@media only screen and (max-width:899px){
    .verifycode{
    // .logo-img{
    //     width: 40px !important;
    //     height: 40px !important;
    //   } 
      .paddtop{
        padding: 175px 0;
      }
    }
}